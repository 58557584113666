<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      scrollable
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-else>
        <v-card-title
          class="py-0 pt-7"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
          </div>
          <div class="ml-1 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            large
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleVoucherModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form lazy-validation ref="voucherForm">
            <v-row no-gutters justify="center">
              <v-col cols="11">
                <v-text-field
                  outlined
                  dense
                  v-model="voucher_code"
                  label="Voucher Code"
                  color="#7253CF"
                  class="formFields"
                  :rules="[rules.min5character]"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="11">
                <v-text-field
                  outlined
                  dense
                  v-model="maximum_usages"
                  label="Maximum Usage"
                  type="number"
                  color="#7253CF"
                  class="formFields"
                  :rules="[rules.required]"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="11">
                <v-autocomplete
                  label="Product"
                  outlined
                  dense
                  v-model="product"
                  :items="productList"
                  class="formFields"
                  item-text="item_name"
                  item-value="item_id"
                  color="#7253CF"
                  :disabled="formLoading"
                  :rules="[rules.required]"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="11">
                <v-autocomplete
                  label="Price Effect"
                  outlined
                  dense
                  v-model="price_mode"
                  :items="priceOptions"
                  class="formFields"
                  item-text="item_name"
                  item-value="item_value"
                  color="#7253CF"
                  :disabled="formLoading"
                  :rules="[rules.required]"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="11">
                <v-text-field
                  outlined
                  dense
                  v-model="value"
                  label="value"
                  type="number"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            class="dialogAction-btnText text-capitalize px-7"
            height="45"
            color="#38227A"
            :loading="formLoading"
            @click="submitForm"
          >
            <span>Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  API_POST_GAME_VOUCHER,
  API_PATCH_GAME_VOUCHER,
  API_GET_GAME_VOUCHER,
  API_ADMIN_GAME_PRODUCT_NAME_LIST,
} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
export default {
  name: "voucherModal",
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      note: "",
      note_by: "",
      price_mode: "",
      voucher_code: "",
      maximum_usages: "",
      product: "",
      value: 0,
      user_id: localStorage.getItem("user_id"),
      productList: [],
      priceOptions: [
        { item_name: "No effect", item_value: "none" },
        { item_name: "Set product price to", item_value: "set" },
        { item_name: "Subtract from product price", item_value: "subtract" },
        { item_name: "Reduce product price by(%)", item_value: "percent" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      showGetters: "gameManagement/getVoucherShow",
      selectGameData: "gameManagement/getGame",
    }),
    show: {
      get() {
        return this.showGetters;
      },
      set(value) {
        return this.toggleVoucherModal({ show: value });
      },
    },
    type() {
      return this.$store.state.gameManagement.voucherModal.type;
    },
    dialogTitle() {
      if (this.type === "add") return "Add Voucher";
      else return "Edit Voucher ";
    },
    actionText() {
      if (this.type === "add") {
        return "Add Voucher";
      } else {
        return "Save";
      }
    },
    toastMessage() {
      if (this.type === "add") {
        return "Voucher Added Successfully.";
      } else {
        return "Voucher Detail Updated.";
      }
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleVoucherModal: "gameManagement/toggleVoucherModal",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      this.getProductList();
      if (this.type === "edit") {
        this.getDetail();
      }
    },
    closeModal() {
      this.loading = false;
      this.formLoading = false;
      this.name = "";
      this.email = "";
      this.mobile = null;
      this.areaOfFocus = null;
      this.areaOfFocusList = [];
    },
    getDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.voucher_code = res.data.voucher_detail.voucher_code;
        this.price_mode = res.data.voucher_detail.price_mode;
        this.value = res.data.voucher_detail.value;
        this.product = res.data.voucher_detail.product;
        this.maximum_usages = res.data.voucher_detail.maximum_usages;

        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["voucher_id"] =
        this.$store.state.gameManagement.voucherModal.voucherID;
      Axios.request_GET(
        API_GET_GAME_VOUCHER,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm() {
      if (this.$refs.voucherForm.validate()) {
        this.formLoading = true;
        const successHandler = (res) => {
          console.log(res);
          this.formLoading = false;
          this.showToast({
            message: this.toastMessage,
            color: "s",
          });
          this.$emit("reload");
          location.reload();
          this.toggleVoucherModal({ show: false });
        };
        const failureHandler = (res) => {
          console.log(res);
          this.formLoading = false;
          this.showToast({
            message: res,
            color: "e",
          });
        };
        let formData = {};
        formData["game"] = this.$route.query.game_id;
        console.log(formData, this.selectGameData);
        formData["voucher_code"] = this.voucher_code;
        formData["maximum_usages"] = this.maximum_usages;
        formData["price_mode"] = this.price_mode;
        formData["value"] = this.value;
        formData["product"] = this.product;
        if (this.type === "add") {
          Axios.request_POST(
            API_POST_GAME_VOUCHER,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        } else {
          formData["id"] =
            this.$store.state.gameManagement.voucherModal.voucherID;
          Axios.request_PATCH(
            API_PATCH_GAME_VOUCHER,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      }
    },
    getProductList() {
      const successHandler = (res) => {
        this.productList = res.data.game_ticket_list;
        console.log("this.productList*******", this.productList);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {};
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        API_ADMIN_GAME_PRODUCT_NAME_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.v-btn.dialogAction-btnText {
  border-radius: 10px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
</style>
